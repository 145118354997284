export const FieldCheckbox = () => import('../../components/field/FieldCheckbox.vue' /* webpackChunkName: "components/field-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FieldFile = () => import('../../components/field/FieldFile.vue' /* webpackChunkName: "components/field-file" */).then(c => wrapFunctional(c.default || c))
export const FieldHelp = () => import('../../components/field/FieldHelp.vue' /* webpackChunkName: "components/field-help" */).then(c => wrapFunctional(c.default || c))
export const FieldInput = () => import('../../components/field/FieldInput.vue' /* webpackChunkName: "components/field-input" */).then(c => wrapFunctional(c.default || c))
export const FieldSelect = () => import('../../components/field/FieldSelect.vue' /* webpackChunkName: "components/field-select" */).then(c => wrapFunctional(c.default || c))
export const FieldTextarea = () => import('../../components/field/FieldTextarea.vue' /* webpackChunkName: "components/field-textarea" */).then(c => wrapFunctional(c.default || c))
export const FieldWrapper = () => import('../../components/field/FieldWrapper.vue' /* webpackChunkName: "components/field-wrapper" */).then(c => wrapFunctional(c.default || c))
export const UserForm = () => import('../../components/user/UserForm.vue' /* webpackChunkName: "components/user-form" */).then(c => wrapFunctional(c.default || c))
export const WidgetCaptcha = () => import('../../components/widget/WidgetCaptcha.vue' /* webpackChunkName: "components/widget-captcha" */).then(c => wrapFunctional(c.default || c))
export const WidgetCheckboxes = () => import('../../components/widget/WidgetCheckboxes.vue' /* webpackChunkName: "components/widget-checkboxes" */).then(c => wrapFunctional(c.default || c))
export const WidgetDatalist = () => import('../../components/widget/WidgetDatalist.vue' /* webpackChunkName: "components/widget-datalist" */).then(c => wrapFunctional(c.default || c))
export const WidgetDate = () => import('../../components/widget/WidgetDate.vue' /* webpackChunkName: "components/widget-date" */).then(c => wrapFunctional(c.default || c))
export const WidgetDateTime = () => import('../../components/widget/WidgetDateTime.vue' /* webpackChunkName: "components/widget-date-time" */).then(c => wrapFunctional(c.default || c))
export const WidgetEntityList = () => import('../../components/widget/WidgetEntityList.vue' /* webpackChunkName: "components/widget-entity-list" */).then(c => wrapFunctional(c.default || c))
export const WidgetForm = () => import('../../components/widget/WidgetForm.vue' /* webpackChunkName: "components/widget-form" */).then(c => wrapFunctional(c.default || c))
export const WidgetItem = () => import('../../components/widget/WidgetItem.vue' /* webpackChunkName: "components/widget-item" */).then(c => wrapFunctional(c.default || c))
export const WidgetPassword = () => import('../../components/widget/WidgetPassword.vue' /* webpackChunkName: "components/widget-password" */).then(c => wrapFunctional(c.default || c))
export const WidgetPhoto = () => import('../../components/widget/WidgetPhoto.vue' /* webpackChunkName: "components/widget-photo" */).then(c => wrapFunctional(c.default || c))
export const WidgetTable = () => import('../../components/widget/WidgetTable.vue' /* webpackChunkName: "components/widget-table" */).then(c => wrapFunctional(c.default || c))
export const WidgetTime = () => import('../../components/widget/WidgetTime.vue' /* webpackChunkName: "components/widget-time" */).then(c => wrapFunctional(c.default || c))
export const WidgetTranslatableText = () => import('../../components/widget/WidgetTranslatableText.vue' /* webpackChunkName: "components/widget-translatable-text" */).then(c => wrapFunctional(c.default || c))
export const AppBtn = () => import('../../components/app/AppBtn.vue' /* webpackChunkName: "components/app-btn" */).then(c => wrapFunctional(c.default || c))
export const AppIcon = () => import('../../components/app/AppIcon.vue' /* webpackChunkName: "components/app-icon" */).then(c => wrapFunctional(c.default || c))
export const AppLayout = () => import('../../components/app/AppLayout.vue' /* webpackChunkName: "components/app-layout" */).then(c => wrapFunctional(c.default || c))
export const AppLoader = () => import('../../components/app/AppLoader.vue' /* webpackChunkName: "components/app-loader" */).then(c => wrapFunctional(c.default || c))
export const AppSpinner = () => import('../../components/app/AppSpinner.vue' /* webpackChunkName: "components/app-spinner" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
